<template>
  <base-error>
    <template slot="heading">Page Not Found</template>
    <template slot="text">
      Whoops! Looks like you're lost. Why don't you go
      <router-link :to="{ name: 'home' }">back home</router-link>?
    </template>
  </base-error>
</template>


<script>
import BaseError from "@/pages/Errors/Base"

export default {
  name: "FourOhFour",

  components: {
    BaseError,
  },
}
</script>
